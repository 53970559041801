import React from 'react'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import CardSection from '../../layout/CardSection'
import { Link } from 'gatsby'
import { Button, Container, Text } from 'theme-ui'
import Breadcrumbs from '../../components/Breadcrumbs/ServiceCrumbs'

export default () => {
  const title = 'Contract Manufacturing'
  const styles = {
    flex: {
      display: 'flex',
    },
    running: {
      fontSize: `1.25em`,
    },
  }
  return (
    <>
      <Seo
        title='Contract Manufacturing'
        description='Already have a formula for a product? Let us be your partner in contract manufacturing. Our manufacturing facility is ISO22716 certified, FDA registered, and we strictly adhere to all GMP standards. All products are manufactured in a 65,000 sq. ft. facility in Boca Raton, Florida, USA.'
      />
      <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs title={title} />
      </Container>
      <Divider space={2} />
      <Stack>
        <Main>
          <PageTitle header='How the Skincare Contract Manufacturing Process Works' />
          <CardSection omitTitle>
            <Text sx={styles.running}>
              Creating a skincare product or line is no easy task, but our
              experienced team at Pacific Beauty can guide you through it to
              reach success. If you work with us for your contract manufacturing
              project, these are the steps we will go through:
              <h3>1. Manufacturing</h3>
              <p>
                Without good manufacturing, a skincare product is nothing. We
                maintain the highest quality standards, adopt the latest
                technological advances, and use well-trained staff to ensure
                that you end up with the best products possible.
              </p>
              <h3>2. Packaging</h3>
              <p>
                No matter how good the manufacturing is, a product also needs
                excellent packaging to attract customers. Our experienced
                professionals will outline your choices and give you advice
                based on your budget and design.
              </p>
              <h3>3. Branding</h3>
              <p>
                Closely linked to your packaging choices is your brand message.
                To differentiate yourself from the competition and make
                potential customers choose your product, it’s essential to have
                a clear idea of what makes your product and company different.
                This can be challenging, but we’re here to guide you through the
                process.
              </p>
              <h3>4. Designing</h3>
              <p>
                After your brand is cemented, you can begin to design your
                packaging. It’s a process that’s best carried out with careful
                deliberation and strategy. After you tell our design team what
                you’re looking for, they’ll send you electronic files for review
                until you’re happy.
              </p>
              <h3>5. Marketing and Selling</h3>
              <p>
                Even after your product is ready for launch, we won’t stop
                supporting you. We understand that this can be the most
                challenging stage in the process, so we’ll ensure you have the
                resources and tools needed to reach customers and launch
                successfully.
              </p>
              <h3>6. Logistics and Regulatory Compliance</h3>
              <p>
                An often-overlooked stage of the skincare manufacturing process
                is the product shipping. Whether you reside domestically in the
                USA or internationally, we can handle logistics for you and
                ensure you’re complying with all relevant regulations and
                customs requirements.
              </p>
              <h3>Final Words </h3>
              <p>
                Learning about this six-step process might seem overwhelming,
                but with our guidance, you’ll fly through it effortlessly.{' '}
                <Link to='/contact/'>Get in touch</Link> with the team at
                Pacific Beauty to learn more.
              </p>
            </Text>
            <Divider space={3} />
            <Button as={Link} to='/contact/'>
              Contact Us
            </Button>
          </CardSection>
        </Main>
      </Stack>
    </>
  )
}
