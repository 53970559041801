import React from 'react'
import { Heading } from 'theme-ui'

const SectionTitle = ({ title, omitTitle }) => {
  return !omitTitle && title ? (
    <>
      <Heading variant='h2'>{title}</Heading>
    </>
  ) : null
}

export default SectionTitle
