import React from 'react'
import HeroBoxed from '@widgets/HeroBoxed'
import { Box, Text } from 'theme-ui'
import PropTypes from 'prop-types'
import SectionTitle from './CardSection.Title'

const styles = {
  wrapper: {
    ml: 3
  },
  running: {
    color: `grayDark`,
    fontWeight: `body`,
    span: {
      fontWeight: 'bold'
    }
  }
}

const CardSection = ({ children, ...props }) => {
  return (
    <HeroBoxed.Wrapper>
      <HeroBoxed.LeftColumn>
        <Box sx={styles.wrapper}>
          <SectionTitle {...props} />
          <Text sx={styles.running}>{children}</Text>
        </Box>
      </HeroBoxed.LeftColumn>
    </HeroBoxed.Wrapper>
  )
}
export default CardSection

CardSection.propTypes = {
  title: PropTypes.string,
  omitTitle: PropTypes.bool
}
