import React from 'react'
import { Text } from 'theme-ui'
import { Link } from 'gatsby'
import colors from '../../@gatsby-theme-flexiblog/theme-ui/theme/color-preset'

const grayDark = colors.gray[700]

const styles = {
  breadcrumbs: {
    textTransform: 'uppercase',
    fontSize: '1.25em',
    color: grayDark,
    display: ['block', 'block', 'block', 'block']
  }
}

export default function BreadCrumbs(props) {
  return (
    <Text sx={styles.breadcrumbs}>
      <Link style={{ color: grayDark }} to='/'>
        Home
      </Link>
      {' >> '}
	  <Link style={{ color: grayDark }} to='/services/'>
        Services
      </Link>
      {' >> '}
       <span dangerouslySetInnerHTML={{ __html: props.title }}></span>
    </Text>
  )
}
